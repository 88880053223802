export default function TitleText(section) {
  switch (section) {
    case "budget":
        return "presupuesto"
    case "location":
        return "nuestra ubicación"
    case "products":
        return "nuestros productos"
    default:
      break;
  }
}
