import React from 'react'
import rowsContent from '../Helpers/RowsContent'
import LinkWithIcon from '../Components/LinkWithIcon'
import Grid from '@material-ui/core/Grid'
import useWindowDimensions from '../Hooks/UseWindowDimensions'
import '../styles/4-views/_adviceRows.scss'

function AdviceRows(props) {

    const { width } = useWindowDimensions()

    return (
        <section className={`advice-row ${props.section}`}>
            <div className="advice-row__wrapper">
                <Grid
                    container
                    className="advice-row__container"
                >
                    {
                        width < 960 &&
                        <Grid
                            item
                            xs={12}
                        >
                            <div className="advice-row__title-box">
                                <h1 className="advice-row__title">
                                    {rowsContent(props.section).icon}
                                    {rowsContent(props.section).text1}
                                </h1>
                            </div>
                        </Grid>
                    }
                    <Grid
                        item
                        xs={12}
                        md={6}
                    >
                        <div className="advice-row__img-box">
                            <img
                                src={rowsContent(props.section).img11}
                                srcSet={
                                    `${rowsContent(props.section).img1} ${rowsContent(props.section).wImg1},
                                        ${rowsContent(props.section).img11} ${rowsContent(props.section).wImg11}`
                                }
                                alt={rowsContent(props.section).text1}
                                className="advice-row__img"
                            />
                        </div>
                    </Grid>
                    <Grid
                        container
                        md={6}
                        className="advice-row__text-container"
                    >
                        {
                            width > 960 &&
                            <Grid
                                item
                                md={12}
                            >
                                <div className="advice-row__title-box">
                                    <h1 className="advice-row__title">
                                        {rowsContent(props.section).icon}
                                        {rowsContent(props.section).text1}
                                    </h1>
                                </div>
                            </Grid>
                        }
                        <Grid
                            item
                            xs={12}
                            md={12}
                        >
                            <div className="advice-row__text-box">
                                <h1 className="advice-row__text">
                                    {rowsContent(props.section).text2}
                                </h1>
                            </div>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={12}
                        >
                            {
                                props.section !== "payment-methods"
                                    ? <LinkWithIcon icon="phone" href="tel:3364293639" />
                                    : <LinkWithIcon section={props.section} icon="open-in-new" href="https://www.agrocanje.com.ar/compra/?gclid=Cj0KCQjw9YWDBhDyARIsADt6sGZwDXBalBUl4a4o19uHoYCwQQnmDILi2DassTP2SOHRVzTw-NO_YtYaAm5WEALw_wcB" />
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </section>
    )
}

export default AdviceRows
