export default function LinkRef(value) {
  switch (value) {
    case "inicio":
      return "#home";
    case "ubicación":
      return "#location";
    case "presupuesto":
      return "#budget";
    case "productos":
      return "#products";
    default:
      break;
  }
}
