import React from 'react'
import { TextareaAutosize, Grid, Button } from '@material-ui/core'
import FormInputs from '../../Components/FormInputs'
import inputsContent from '../../Helpers/FormInputsContent'
import useWindowDimensions from '../../Hooks/UseWindowDimensions'

export default function ContactForm(props) {

    const { width } = useWindowDimensions();

    return (
        <form
            className="contact__form"
            onSubmit={e => props.onSubmit(e)}
        >
            <div className="contact__form-inputs">
                <Grid
                    container
                    spacing={2}
                    className="contact__grid-container"
                >
                    {
                        inputsContent.map((input, index) =>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                key={index}
                                className={
                                    props.inView
                                        ? "contact__inner-grid-item --active-from-top-bottom"
                                        : "contact__inner-grid-item"
                                }
                            >
                                <FormInputs
                                    type={input.type}
                                    name={input.name}
                                    label={input.label}
                                />
                            </Grid>
                        )
                    }
                    <Grid item
                        xs={12}
                        sm={6}
                        className={
                            props.inView
                                ? "contact__inner-grid-item --active-from-top-bottom"
                                : "contact__inner-grid-item"
                        }
                    >
                        <div className="input-box">
                            <select required className="" name="selected_product">
                                <option defaultValue>Seleccione un producto</option>
                                <option value="Protector de motor">Protector de motor VIGIA</option>
                                <option value="Calibrador de neumáticos">Calibrador de neumáticos VIGIA</option>
                                <option value="Enfriador viesa">Climatizador VIESA</option>
                                <option value="Satelital GESTYA">Satelital GESTYA</option>
                                <option value="Monitor vitran">Monitor VITRAN</option>
                                <option value="Tacógrafo VDO">Tacógrafo VDO</option>
                            </select>
                        </div>
                    </Grid>
                    <Grid item
                        xs={12}
                        className={
                            props.inView
                                ? "contact__grid-item-msg --active-from-top-bottom"
                                : "contact__grid-item-msg"
                        }
                    >
                        <TextareaAutosize
                            as="textarea"
                            aria-label="textarea"
                            rows={
                                width > 960
                                    ? 5
                                    : 10
                            }
                            name="message"
                            placeholder="Complete aquí los datos del vehículo"
                            className="contact__email-body"
                            required
                        />
                    </Grid>
                </Grid>
            </div>
            <Button
                type="submit"
                variant="contained"
                className="cta-button"
            >
                {/* <a> */}
                    enviar
                    {/* </a> */}
            </Button>
        </form>
    )
}
