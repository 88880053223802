import React from 'react'
import titleText from '../Helpers/TitleText.js';
import '../styles/2-components/_title.scss'

export default function Title(props) {
    return (
        <div className={
            props.section === "budget"
            ? "section__title-box --left-translated --active-from-top-bottom"
            : props.inView
                ? "section__title-box --active-from-top-bottom"
                : "section__title-box"
        } >
            <h1 className="section-title">{titleText(props.section)}</h1>
            <hr />
        </div >
    )
}
