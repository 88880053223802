import React from 'react'
import List from '@material-ui/icons/PlaylistAddCheckRounded';
import Button from '@material-ui/core/Button'
import rowsContent from '../Helpers/RowsContent'
import scrollTo from '../Helpers/scrollTo'
import '../styles/4-views/_cta.scss'

function CtaRow(props) {

    return (
        <section className="cta-row">
            <div className="cta-row__wrapper">
                <div className="cta-row__content">
                    <div className="cta-row__text-box">
                        <h1 className="cta-row__text --text-active">
                            <span>
                                <List className="budget-list-icon" />
                            </span>
                            {rowsContent(props.section).text1}
                        </h1>
                    </div>
                    <div className="button-box">
                        <Button variant="contained" className="cta-button">
                            <a href="#budget" onClick={e => scrollTo(e)}>
                                click aquí
                                </a>
                        </Button>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default CtaRow
