import React from 'react'

export default function Icon(props) {
    return (
        <div
            key={props.index}
            className="footer-socials__icon-box"
        >
            <a
                href={props.icon.link}
                target="_blank"
                rel="noreferrer"
            >
                <img
                    src={props.icon.iconImg}
                    alt={`icon-${props.icon.iconImg.toString()}`}
                    className="footer-socials__icon"
                    loading="lazy"
                />
            </a>
        </div>
    )
}
