import CheckOutRow1 from '../Assets/Images/Rows/CheckOutRow1.jpg'
import CheckOutRow11 from '../Assets/Images/Rows/CheckOutRow11.jpg'
import InSituRow1 from '../Assets/Images/Rows/InSituRow1.png'
import InSituRow11 from '../Assets/Images/Rows/InSituRow11.png'
import PaymentMethodsRow1 from '../Assets/Images/Rows/PaymentMethodsRow1.jpg'
import PaymentMethodsRow11 from '../Assets/Images/Rows/PaymentMethodsRow11.jpg'
import WarnTriangle from '@material-ui/icons/WarningRounded'
import Map from '@material-ui/icons/LocationOn'
import Wallet from '@material-ui/icons/AccountBalanceWallet'

export default function rowsContent(section) {
  switch (section) {
    case "cta":
      return {
        text1: "Pedí tu presupuesto",
        text2: "",
      };
    case "checkout":
      return {
        text1: "¿Todavía no realizaste el chequeo de tu VIGIA motor?",
        text2: "comunicate y agendá una revisión general sin costo.",
        img1: CheckOutRow1,
        img11: CheckOutRow11,
        wImg1:'640w',
        wImg11:'1280w',
        icon: <WarnTriangle className="warn-icon" />,
      };
    case "insitu":
      return {
        text1: "Nos acercamos hasta TU domicilio",
        text2: "que no te agarre en plena campaña, agendá un turno con anticipación.",
        img1: InSituRow1,
        img11: InSituRow11,
        wImg1:'360w',
        wImg11:'720w',
        icon: <Map className="map-icon" />,
      };
    case "payment-methods":
      return {
        text1: "Enterate nuestros medios de pago",
        text2: "además de AHORA 12, sumamos AGROCANJE el sistema de pagos que te permite abonar con granos.",
        img1: PaymentMethodsRow1,
        img11: PaymentMethodsRow11,
        wImg1:'640w',
        wImg11:'1052w',
        icon: <Wallet className="wallet-icon" />,
      };
    default:
      break;
  }
}
