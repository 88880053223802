import React, { useState, useRef, useEffect } from 'react'
import carouselContent from '../Helpers/Carousel.js'
import '../styles/2-components/_carousel.scss'

function Carousel() {

    const show = useRef(0)
    const [showSlide, setShowSlide] = useState(show.current)

    function changeSlide() {
        if (show.current > 5) show.current = 0
        setShowSlide(show.current)
        show.current = show.current + 1
    }

    useEffect(() => {
        function handleInterval() {
            changeSlide()
            setInterval(() => {
                changeSlide()
            }, 4000);
        }
        handleInterval();
    }, [])

    return (
        <div className="carousel">
            <div className="carousel__wrapper">
                <div className="carousel__slide-wrapper">
                    < div className="carousel__slide">
                        <img
                            className="carousel__slide--active-img"
                            src={carouselContent[showSlide].slide2}
                            srcSet={
                                `${carouselContent[showSlide].slide} ${carouselContent[showSlide].wSlide},
                                 ${carouselContent[showSlide].slide2} ${carouselContent[showSlide].wSlide2}`
                            }
                            alt="slide-img"
                        />
                        <div className="carousel__bullets" >
                            {[0, 1, 2, 3, 4, 5].map(item =>
                                <div
                                    key={item}
                                    className={
                                        item === showSlide
                                            ? 'carousel__bullet --active-bullet'
                                            : 'carousel__bullet'
                                    }
                                >
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Carousel
