import emailjs from "emailjs-com";

export default function sendEmail(e) {
  return emailjs
    .sendForm(
      "service_d79tn11",
      "template_q5cjd8g",
      e.target,
      "user_ySUj3Wgz9VqyXz2rtrxTv"
    )
    .then(
      result => {
        console.log(result.text);
        return result;
      },
      error => {
        console.log(error.text);
        return error;
      }
    );
}
