import React from 'react'
import LinkRef from '../Helpers/LinkRef'
import useWindowDimensions from '../Hooks/UseWindowDimensions'

export default function NavLink(props) {

    const { width } = useWindowDimensions()

    return (
        <div
            className={
                width < 1024
                    ? "nav__drawer-item-box"
                    : "nav__bar-item-box"
            }
        >
            <a
                href={LinkRef(props.item)}
                className={
                    width < 1024
                        ? "nav__drawer-item"
                        : "nav__bar-item"
                }
                onClick={e => props.onClick(e)}
            >
                {props.item}
            </a>
        </div>
    )
}
