import React, { useState, useEffect, Fragment } from 'react'
import LoadingSpinner from './Components/LoadingSpinner'
import Nav from './Layout/Home/Nav'
import HomePage from './Pages/HomePage'
import scrollTo from './Helpers/scrollTo'

function App() {

  const [loadingSpinner, setLoadingSpinner] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoadingSpinner(false), 1500)
  }, [])

  return (
    <div className="App">
      {loadingSpinner
        ? <LoadingSpinner />
        : <Fragment>
          <Nav onClick={e => scrollTo(e)} />
          <HomePage />
        </Fragment>
      }
    </div>
  );
}

export default App;
