import slide1 from "../Assets/Images/Carousel/slide1.png";
import slide2 from "../Assets/Images/Carousel/slide2.png";
import slide3 from "../Assets/Images/Carousel/slide3.png";
import slide4 from "../Assets/Images/Carousel/slide4.png";
import slide11 from "../Assets/Images/Carousel/slide1.png";
import slide22 from "../Assets/Images/Carousel/slide2.png";
import slide33 from "../Assets/Images/Carousel/slide3.png";
import slide44 from "../Assets/Images/Carousel/slide4.png";
import slide55 from "../Assets/Images/Carousel/slide55.jpeg";
import slide66 from "../Assets/Images/Carousel/slide66.png";

const carouselContent = [
  { 
    slide: slide1, 
    slide2: slide11, 
    wSlide: '430w',
    wSlide2: '596w'
  },
  {
    slide: slide2,
    slide2: slide22,
    wSlide: '430w',
    wSlide2: '596w'
  },
  {
    slide: slide3,
    slide2: slide33,
    wSlide: '592w',
    wSlide2: '929w'
  },
  {
    slide: slide4,
    slide2: slide44,
    wSlide: '592w',
    wSlide2: '933w'
  },
  {
    slide: slide55,
    slide2: slide55,
    wSlide: '592w',
    wSlide2: '933w'
  },
  {
    slide: slide66,
    slide2: slide66,
    wSlide: '592w',
    wSlide2: '933w'
  },
];

export default carouselContent;
