import success from '../Assets/Icons/Actions/success.svg'
import error from '../Assets/Icons/Actions/error.svg'
import loadingSpinner from '../Assets/Animations/loadingSpinner.svg'

const content = msg => {
    switch (msg) {
        case "msgOk":
            return { title: "Email enviado correctamente", anim: success, showButton: true }
        case "msgError":
            return { title: "Algo salió mal, reintente.", anim: error, showButton: true }
        case "msgLoading":
            return { title: "Un momento por favor...", anim: loadingSpinner, showButton: false }
        default:
            break;
    }
}

export default content
