import React from 'react'
import OpenInNew from '@material-ui/icons/OpenInNew'
import TelephoneFill from '@material-ui/icons/PhoneRounded'
import '../styles/2-components/_linkWithIcon.scss'

export default function LinkWithIcon(props) {
    return (
        <div className="link-icon__box">
            {props.icon === "phone" &&
                <a className="link" href={props.href}>
                    < TelephoneFill className={`${props.icon}-icon`} />
                    Llamar
                </a>
            }
            {props.icon === "open-in-new" &&
                <a className="link" target="_blank" rel="noreferrer" href={props.href}>
                    <OpenInNew className={`${props.icon}-icon`} />
                    {
                        props.section === "payment-methods"
                            ? "Conocé más"
                            : "Abrir mapa"
                    }
                </a>
            }
        </div>
    )
}
