export default async function scrollTo(e) {
  // if (prevScrollId === "#our-products") {
  //   prevScrollId = "";
  //   setTimeout(() => {window.scrollBy(0, -75)}, 1000);
  //   return;
  // }
  if (e === undefined) return;
  let elemRef = e.target.href;
  let strArr = elemRef.split("#");
  let elemId = "#" + [...strArr].pop();
  if (document.querySelector(elemId)) {
    e.preventDefault();
    let sh =
      window.scrollY +
      document.querySelector(elemId).getBoundingClientRect().top;
    window.scroll(0, sh - 75);
  }
}
