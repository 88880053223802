import React from 'react'
import Title from '../Components/Title'
import LinkWithIcon from '../Components/LinkWithIcon'
import '../styles/4-views/_location.scss'

function Location() {
    return (
        <section
            className="location"
            id="location"
        >
            <div className="location__wrapper">
                <Title section="location" />
                <div className="location__map-box">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d208.25926339894963!2d-60.25242179632714!3d-33.36719637435432!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95b767cfd6faefbd%3A0xad9b0b72f87396b9!2sCLIMATIZADORES%20SAN%20NICOLAS%20SA!5e0!3m2!1ses-419!2sar!4v1616678889942!5m2!1ses-419!2sar"
                        allowFullScreen
                        loading="lazy"
                        title="Get to know us"
                        className="location__map"
                    ></iframe>
                </div>
                <div className="location__text-box">
                    <p className="location__text">
                        Conocemos las necesidades de nuestros clientes, por eso si te encontrás viajando por autopista <span>RUTA 9</span>, estamos en el <span>km 231</span> a la altura de <span>San Nicolás de los Arroyos</span>. Podés acercarte a nuestro local y solicitar un servicio de emergencia por cualquier inconveniente en tus equipos <span>VIGIA, VIESA y VDO</span>.
                    </p>
                    <LinkWithIcon
                        icon="open-in-new"
                        href="https://www.google.com.ar/maps/place/CLIMATIZADORES+SAN+NICOLAS+SA/@-33.3663239,-60.253554,17.5z/data=!4m13!1m7!3m6!1s0x95b7671cbe10dbd1:0xa8b04cd344073b36!2sPellegrini+2281,+San+Nicol%C3%A1s+de+Los+Arroyos,+Provincia+de+Buenos+Aires!3b1!8m2!3d-33.367129!4d-60.252726!3m4!1s0x95b767cfd6faefbd:0xad9b0b72f87396b9!8m2!3d-33.3672106!4d-60.252287"
                    />
                </div>
            </div>
        </section>
    )
}

export default Location
