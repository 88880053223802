import React from 'react'
import Title from '../Components/Title'
import ProductCard from '../Components/ProductCard'
import { products } from '../Helpers/OurProductsContent'
import { Grid } from '@material-ui/core'
import '../styles/4-views/_ourProducts.scss'

function OurProducts(props) {

    return (
        <section
            className="our-products"
            id="products"
        >
            <div className="our-products__wrapper">
                <Title section="products" />
                <div className="our-products__items">
                    <Grid
                        container
                        spacing={2}
                    >
                        {products.map((product, index) =>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                lg={4}
                                key={index}
                                className="our-products__grid-item"
                            >
                                <ProductCard
                                    productSrc={product.src}
                                    productText={product.product}
                                    brandLogo={product.brandLogo}
                                    index={index}
                                />
                            </Grid>
                        )}
                    </Grid>
                </div>
            </div>
        </section>
    )
}

export default OurProducts