import React from 'react'
import Title from '../Components/Title'
import ContactForm from '../Layout/Budget/ContactForm'
import '../styles/4-views/_budget.scss'

function Budget(props) {

    return (
        <section
            className="budget"
            id="budget"
        >
            <div className="budget__wrapper">
                <Title section="budget" />
                <ContactForm onSubmit={e => props.onSubmit(e)} />
            </div>
        </section>
    )
}

export default Budget