import React, { Fragment } from 'react'
import content from '../Helpers/ModalContent'
import Button from '@material-ui/core/Button'
import '../styles/2-components/_modal.scss'

function Modal(props) {

    return (
        <Fragment>
            {
                props.showModal &&
                <div className="modal">
                    <div className="modal__overlay">
                        <div className="modal--success">
                            <div className="modal__content-wrapper">
                                <div className="modal__content-box">
                                    <h4 className="modal__content-title">
                                        {content(props.msg).title}
                                    </h4>
                                    <div className={"modal__anim-box"}>
                                        <img
                                            src={content(props.msg).anim}
                                            alt="error-success"
                                        />
                                    </div>
                                </div>
                                <div className="button-box">
                                    {
                                        content(props.msg).showButton &&
                                        <Button
                                            variant="contained"
                                            className="modal-button"
                                            onClick={e => props.onClick(e)}
                                        >
                                            {
                                                props.msg === "msgOk"
                                                    ? "ok"
                                                    : "aceptar"
                                            }
                                        </Button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    )
}

export default Modal

