import Whatsapp from '../Assets/Icons/Footer/whatsapp.svg'
import Mail from '../Assets/Icons/Footer/gmail.svg'
import Instagram from '../Assets/Icons/Footer/instagram.svg'

const socials = [
  {
    iconImg: Whatsapp,
    link:
      "https://api.whatsapp.com/send/?phone=5493364293639&text&app_absent=0",
    shortLink: "+5493364293639",
  },
  {
    iconImg: Instagram,
    link: "https://www.instagram.com/vigiasannicolas/?hl=es-la",
    shortLink: "@vigiasannicolas",
  },
  {
    iconImg: Mail,
    link:
      "mailto:climatizadoressn.ventas@gmail.com?subject=Consulta%20desde%20sitio%20web&body=Escriba%20aquí%20tu%20consulta...%0A%0APuede%20adjuntar%20archivos%20si%20lo%20desea.%20",
    shortLink: "climatizadoressn.ventas@gmail.com",
  },
];

const links = ["inicio", "presupuesto", "ubicación"];

export { socials, links };
