import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import NavLink from './NavLink'
import { items } from '../Helpers/NavLinks'
import '../styles/2-components/_drawer.scss'

export default function Drawer(props) {

    const [showDrawer, setShowDrawer] = useState(false)

    useEffect(() => setShowDrawer(props.open), [props.open])

    return (
        showDrawer &&
        <div className="nav__drawer" >
            <div className="nav__drawer-wrapper --active-item-from-right">
                <Grid
                    container
                    spacing={4}
                    className="nav__drawer-items"
                >
                    {items.map((item, index) =>
                        <Grid
                            item
                            className="nav__drawer-items__grid-item"
                            key={index}
                            xs={12}
                        >
                            <NavLink
                                item={item}
                                onClick={e => props.onClick(e)}
                            />
                        </Grid>
                    )}
                </Grid>
            </div>
        </div>
    )
}
