import React, { useState } from 'react'
import Home from '../Views/Home'
import Cta from '../Views/Cta'
import AdviceRows from '../Views/AdviceRows'
import OurProducts from '../Views/OurProducts'
import Location from '../Views/Location'
import Budget from '../Views/Budget'
import Footer from '../Views/Footer'
import Modal from '../Components/Modal'
import { Grid } from '@material-ui/core'
import sendEmail from '../Helpers/sendEmail'
import useWindowDimensions from '../Hooks/UseWindowDimensions'
import '../styles/5-Pages/_homePage.scss'

function HomePage() {

  const { width } = useWindowDimensions()

  const [showModal, setShowModal] = useState(false)
  const [msg, setMsg] = useState('msgLoading')

  async function handleEmail(e) {
    const emailState = await sendEmail(e);
    if (emailState && emailState.text === "OK") {
      setMsg("msgOk");
      e.target.reset();
    } else if (emailState) {
      setMsg("msgError");
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    setShowModal(true);
    handleEmail(e);
  }

  function handleClose(e) {
    e.preventDefault();
    setShowModal(prev => !prev);
    setMsg("msgLoading");
  }

  return (
    <div className="Home-page">
      <Home />
      {
        width < 768 &&
        < Grid
          container
          className="cta-quote__grid-container"
        >
          <Grid
            item
            xs={12}
            className="cta-quote__grid-item"
          >
            <Cta section="cta" />
          </Grid>
        </ Grid>
      }
      <Grid
        container
        className="advice-row__grid-container"
      >
        <Grid
          item
          xs={12}
        >
          <AdviceRows section="checkout" />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <AdviceRows section="insitu" />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <AdviceRows section="payment-methods" />
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          xs={12}
        >
          <OurProducts />
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          xs={12}
        >
          <Location />
        </Grid>
        <Grid
          item
          xs={12}
          className="budget__grid-item"
        >
          <Budget onSubmit={e => handleSubmit(e)} />
        </Grid>
        {
          showModal &&
          <Modal
            showModal={showModal}
            msg={msg}
            onClick={e => handleClose(e)}
          />
        }
      </Grid>
      <Footer />
    </div >
  );
}

export default HomePage;
