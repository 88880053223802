import React from 'react'
import FooterLink from '../Components/FooterLink'
import Icon from '../Components/Icon'
import { socials, links } from '../Helpers/FooterContent'
import Grid from '@material-ui/core/Grid'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import useWindowDimensions from '../Hooks/UseWindowDimensions'
import '../styles/4-views/_footer.scss'


export default function Footer() {

    const { width } = useWindowDimensions()

    return (
        <footer className="footer">
            <div className="footer__wrapper">
                <Grid container>
                    <Grid
                        item
                        xs={12}
                    >
                        <div className="footer__arrow-box">
                            <a
                                href="#home"
                                alt="back home arrow button"
                            >
                                <ChevronLeft />
                            </a>
                            <hr />
                        </div>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                    >
                        <div className="footer-socials">

                            <div className="footer-socials__title-box">
                                <h1 className="footer-socials__title">
                                    contactanos a través de
                                </h1>
                            </div>
                            <div className="footer-socials__icons-wrapper">
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    {
                                        socials.map((icon, index) =>
                                            <Grid
                                                item
                                                xs={4}
                                                md={12}
                                                key={index}
                                            >
                                                <Icon icon={icon} />
                                                {
                                                    width > 960 &&
                                                    <FooterLink
                                                        socialLink={icon.link}
                                                        shortLink={icon.shortLink}
                                                    />
                                                }
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                    >
                        <div className="footer-links">
                            <div className="footer-links__title-box">
                                <h1 className="footer-links__title">
                                    links
                                </h1>
                            </div>
                            <div className="footer-links__wrapper">
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    {
                                        links.map((link, index) =>
                                            <Grid
                                                item
                                                xs={12}
                                                key={index}
                                            >
                                                <FooterLink link={link} />
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </footer>
    )
}

