import React from 'react'
import Header from '../Layout/Home/Header'
import '../styles/4-views/_home.scss'


export default function Home() {

    return (
        <div className="home" id="home">
            <Header />
        </div>
    )
}
