import React from 'react'
import NavLink from './NavLink'
import Grid from '@material-ui/core/Grid'
import { items } from '../Helpers/NavLinks'
import '../styles/2-components/_navBar.scss'

export default function NavBar(props) {
    return (
        <div className="nav__bar">
            <div className="nav__bar-items">
                <Grid container>
                    {items.map((item, index) =>
                        <Grid
                            item
                            md={3}
                            key={index}
                        >
                            <NavLink
                                onClick={(e) => props.onClick(e)}
                                item={item}
                            />
                        </Grid>
                    )}
                </Grid>
            </div>
        </div>
    )
}
