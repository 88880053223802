import React, { Fragment } from 'react'
import loadingSpinner from '../Assets/Images/Spinner/loadingSpinner.png'
import loadingSpinner2 from '../Assets/Images/Spinner/loadingSpinner2.png'
import useWindowDimensions from '../Hooks/UseWindowDimensions'
import '../styles/2-components/_loadingSpinner.scss'

export default function LoadingSpinner() {

    const { width } = useWindowDimensions();

    return (
        <div className="loading-spinner">
            <div className="loading-spinner__wrapper">
                {
                    width < 600
                        ? <Fragment>
                            <h1 className="loading-spinner__title">climatizadores</h1>
                            <h1 className="loading-spinner__title">san nicolas s.a.</h1>
                        </Fragment>
                        : <h1 className="loading-spinner__title">climatizadores san nicolas s.a.</h1>
                }
                <h3 className="loading-spinner__subtitle">TU servicio oficial</h3>
                <img
                    src={loadingSpinner2}
                    srcSet={
                        `${loadingSpinner2} 300w, 
                        ${loadingSpinner} 600w`
                    }
                    alt="logo vigia"
                    className="loading-spinner__img"
                />
            </div>
        </div>
    )
}
