import React from 'react'
import Carousel from '../../Components/Carousel'
import '../../styles/3-layout/home/_header.scss'

function Header() {
    return (
        <header className="header">
            <Carousel/>
        </header>
    )
}

export default Header
