import React from 'react'
import lazyframe from 'lazyframe'
import '../styles/2-components/_productCard.scss'

export default function ProductCard(props) {

    lazyframe('.our-products__item', {
        apikey: undefined,
        debounce: 250,
        lazyload: true,
    })

    return (
        <div className="our-products__item-box">
            {
                props.index !== 5
                    ? <iframe
                        src={props.productSrc}
                        frameBorder="0"
                        allowFullScreen
                        title="Products for selling"
                        className="our-products__item"
                    ></iframe>
                    : <img
                        src={props.productSrc}
                        alt="tacografos VDO"
                        className="our-products__item our-products__img-item"
                    />
            }
            <div className="our-products__item-text-box">
                <p className="our-products__item-text --opacity--0to1">
                    {props.productText}
                </p>
                <img
                    src={props.brandLogo}
                    alt={props.productSrc}
                    className="our-products__brand-logo"
                />
            </div>
        </div>
    )
}
