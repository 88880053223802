import tacografoImg from '../Assets/Images/Products/tacografo.png';
import VigiaLogo from '../Assets/Images/Logos/VigiaLogo.png'
import ViesaLogo from '../Assets/Images/Logos/ViesaLogo.png'
import VitranLogo from '../Assets/Images/Logos/VitranLogo.png'
import GestyaLogo from '../Assets/Images/Logos/GestyaLogo.png'
import VdoLogo from '../Assets/Images/Logos/VdoLogo.png'

export const products = [
  {
    product: "protector de motor",
    brandLogo:VigiaLogo,
    src: "https://www.youtube.com/embed/KGcq95sW7hs",
  },
  {
    product: "calibrador de neumáticos",
    brandLogo:VigiaLogo,
    src: "https://www.youtube.com/embed/dWZVfbuba_E",
  },
  {
    product: "climatizador",
    brandLogo:ViesaLogo,
    src: "https://www.youtube.com/embed/UmoD5aYoMs0",
  },
  {
    product: "satelital",
    brandLogo:GestyaLogo,
    src: "https://www.youtube.com/embed/Y5c3nNWTHOQ",
  },
  {
    product: "monitor",
    brandLogo:VitranLogo,
    src: "https://www.youtube.com/embed/2RSDfXDu3LQ",
  },
  {
    product: "tacógrafos",
    brandLogo:VdoLogo,
    src: tacografoImg,
  },
];
